import React from "react";
import styled from "styled-components";
//import Button from "../../../../../components/Button";

const Colors = {
    primary: "#242a33",
    secondary: "#e8d905",
    medium: "#343A40",
    backColor: "#212529",
 };

export const FormEdit = styled.div`
   width: 60%;
   margin: 0 auto;
   padding: 1em;
   @media screen and (max-width: 1000px) {
      width: 85%;
   }
   @media screen and (max-width: 800px) {
      width: 80%;
   }
   @media screen and (max-width: 500px) {
      width: 100%;
   }
`;

export const CurrentImageContainer = styled.div`
   width: 100%;
   position: relative;
   overflow: hidden;
   & img {
      width: 100%;
   }
   & .HoverEvent {
      width: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      height: 100%;
      background: rgba(90, 90, 90, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
   }
   &:hover .HoverEvent {
      top: 0;
      left: 0;
   }
`;

export const RowDiv = styled.div`
   display: flex;
   flex-wrap: wrap;
   background-color: ${(props) => props.back || "transparent"};
   margin: ${(props) => props.margin || ""};
   border-top: 2px dashed rgba(100, 100, 100, 0.6);
   padding-top: 1em;
`;
export const ColDiv = styled.div`
   width: ${(props) => gridSystem(props.colmd)};
   margin-left: ${(props) => gridSystem(props.marginLeftMd)};
   margin-right: ${(props) => gridSystem(props.marginRightMd)};
   padding: ${(props) => props.padding || "0"}
   background-color: ${(props) => props.back || "transparent"};

   @media screen and (max-width: 600px){
      width: ${(props) => (props.colsm ? gridSystem(props.colsm) : "6")};
      margin-left: ${(props) => gridSystem(props.marginLeftSm)};
      margin-right: ${(props) => gridSystem(props.marginRightSm)};
   }
`;

const gridSystem = (size) => {
   if (!size) return false;
   return mediumDevice(size);
};
const mediumDevice = (num) => {
   if (!num) return false;
   return `${(100 / 12) * num}%`;
};


export const Select = styled.select`
   outline: none;
   border: none;
   width: 100%;
   border-bottom: 2px solid ${Colors.medium};
   padding: 0.5em;
   font-size: 1.1em;
   margin-bottom: ${props => props.marginB || "1em"};
   & option {
      padding: 0.5em;
   }
`;
export const Input = styled.input`
   outline: none;
   border: none;
   width: 100%;
   border-bottom: 2px solid ${props => props.border || Colors.medium};
   background-color: transparent;
   padding: 0.5em;
   font-size: 1.1em;
   margin-bottom: 1em;
   color: ${props => props.color || Colors.medium};
   &::-webkit-input-placeholder {
      color: grey;
   }
   height: ${props => props.height || null};
`;

export const Label = styled.label`
   font-size: ${props => props.size || "1.1em"};
   margin-bottom: ${props => props.marginBottom || "1em"};
   display: inline-block;
   color: ${props => props.color || Colors.medium};
`;
