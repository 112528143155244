import React, {Component} from "react";
import {
   getToken, 
   getPodcastsService, 
   postPodcastService, 
   updatePodcastService
} from "../../helpers/Services";

export const PodcastsContext = React.createContext();
export default class PodcastsProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         podcastsData: [],
         // add
         addPodcast: this.addPodcast,
         closeModalAdd: this.closeModalAdd,
         modalAddIsOpen: false,
         openModalAdd: this.openModalAdd,

         // edit
         modalEditIsOpen: false,
         closeModalEdit: this.closeModalEdit,
         openModalEdit: this.openModalEdit,
         toEditPodcast: [],
         updatePodcast: this.updatePodcast,
         // gral
         errorsForm: "",
      };
   }

   UNSAFE_componentWillMount() {
      this.getPodcasts();
   }
   
   getPodcasts = () => {
      getToken().then(token => {
         const data = {token};
         getPodcastsService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  this.setState({
                     podcastsData: [...data],
                  });
               }
            })
            .catch(err => console.log(err));
      });
   };
   
   // add
   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
      
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };

   addPodcast = e => {
      e.preventDefault();
      const myForm = e.target;
      const {podcastsData} = this.state;
      const youtube_id = myForm.youtube_id.value;
      const data = {
         youtube_id,
      };
      getToken(data).then(token => {
         const toSend = {...data, token};
         postPodcastService(toSend)
            .then(resp => resp.json())
            .then(resp => {
               const {status, data} = resp;
               if (status === "success") {
                  const newPodcasts = [...podcastsData, {...data}];
                  this.setState(
                     {
                        podcastsData: newPodcasts,
                     },
                     () => {
                        this.closeModalAdd();
                     },
                  );
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });

   };
   
   // edit
   updatePodcast = e => {
      e.preventDefault();
      const myForm = e.target;
      const youtube_id = myForm.youtube_id.value;
      const id = Number(e.target.id.value);
      const updatedPodcast = {
         id,
         youtube_id,
      };
      const {podcastsData} = this.state;
      const newPodcasts = podcastsData.map(podcast => {
         if (podcast.id === id) {
            return {...updatedPodcast};
         }
         return podcast;
      });

      getToken().then(token => {
         const data = {...updatedPodcast, token};
         updatePodcastService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {status} = resp;
               console.log(resp);
               if (status === "success") {
                  this.setState(
                     {
                        podcastData: newPodcasts,
                     },
                     () => {
                        this.closeModalEdit();
                     },
                  );
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });

      this.setState(
         {
            podcastsData: newPodcasts,
         },
         () => {
            this.closeModalEdit();
         },
      );
   };
   closeModalEdit = () => {
      this.setState({modalEditIsOpen: false, toEditPodcast: {}, errorsForm: ""});
   };
   openModalEdit = id => {
      const {podcastsData} = this.state;
      let toEditPodcast = podcastsData.find(x => Number(x.id) === Number(id));
      return this.setState({modalEditIsOpen: true, toEditPodcast: toEditPodcast});
   };

   render() {
      return <PodcastsContext.Provider value={this.state}>{this.props.children}</PodcastsContext.Provider>;
   }
}
