import React, {Component} from "react";
import Container from "../../components/Container";
import PodcastsProvider, {PodcastsContext} from "./PodcastsProvider";
import TablePodcasts from "./TablePodcasts";
import AddPodcastModal from "./AddPodcastModal";
import EditPodcastModal from "./EditPodcastModal";

export default class Podcasts extends Component {
    constructor(props) {
       super(props);
       this.state = {
          modalIsOpen: false,
       };
    }
    render() {
       return (
          <Container {...this.props}>
             <PodcastsProvider {...this.props}>
                <PodcastsContext.Consumer>
                {
                    ({
                        podcastsData,
                        openModalAdd,
                        openModalEdit,
                        closeModalEdit,
                    }) => (
                        <>
                            <TablePodcasts
                              podcastsData={podcastsData}
                              openModalAdd={openModalAdd}
                              closeModalEdit={closeModalEdit}
                              openModalEdit={openModalEdit}
                            />
                            <AddPodcastModal />
                            <EditPodcastModal />
                        </>
                    )

                }
                </PodcastsContext.Consumer>
             </PodcastsProvider>
          </Container>
       );
    }
 }
 