import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Seeker, TableAction, ItemHeader} from "../../components/TableComponents";
import {ReactComponent as EditIcon} from "../../icons/edit.svg";
import Colors from "../../constants/Colors";
import HeaderView from "../../components/toViews/HeaderView";
import {AddButton} from "../../components/Button";

const HeaderActions = ({onFilter, title, openModal}) => (
   <HeaderView title={title}>
      <ItemHeader>
         <AddButton onClick={openModal} />
      </ItemHeader>
      <ItemHeader>
         <Seeker onFilter={onFilter} place="Buscar por comediante" />
      </ItemHeader>
   </HeaderView>
);

export default class TableEventComedians extends Component {
   constructor(props) {
      super(props);
      this.state = {
         DataEventComedians: this.props.eventComediansData,
         filterText: "",
      };
   }
   componentDidMount() {
      this.onFilter();
   }
   UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.eventComediansData === this.state.DataEventComedians) return false;
      this.setState(
         {
            DataEventComedians: this.props.eventComediansData,
         },
         () => this.onFilter(this.state.filterText),
      );
   }
   onFilter = val => {
      this.filterItems(val);
   };
   filterItems = val => {
      const {DataEventComedians} = this.state;
      const filterText = val || "";
      if (filterText.length > 2) {
         let filteredItems = DataEventComedians.filter(theme => theme.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
         return this.setState({
            filteredItems: filteredItems,
         });
      }
      return this.setState({
         filteredItems: DataEventComedians,
      });
   };
   Columns = [
      {
         name: "Id",
         selector: "id",
         sortable: true,
         maxWidth: "120px",
      },
      {
         name: "Nombre",
         selector: "name",
         sortable: true,
      },
      {
         name: "Descripción",
         selector: "description",
         sortable: true,
      }
   ];

   render() {
      const {filteredItems} = this.state;
      const paginationOptions = {rowsPerPageText: "Filas por página", rangeSeparatorText: "de"};
      return (
         <DataTable
            title="Comediantes"
            header={null}
            columns={this.Columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={<HeaderActions onFilter={this.onFilter} title="Comediantes" openModal={this.props.openModalAdd} />}
            pagination
            paginationComponentOptions={paginationOptions}
            fixedHeader
            noDataComponent="No hay comediantes registrados"
            style={{display: "block", height: "80%", overflowX: "initial"}}
         />
      );
   }
}
