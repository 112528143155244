import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select, Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import {PodcastsContext} from "./PodcastsProvider";

export default EditPodcast => {
   return (
      <PodcastsContext.Consumer>
         {({toEditPodcast, modalEditIsOpen, closeModalEdit, updatePodcast}) => {
            return (
               <GlobalModal modalIsOpen={modalEditIsOpen} closeModal={closeModalEdit} widthCard="40%">
                  <HeadModal closeModal={closeModalEdit}>
                     <TitleModal>Editar Podcast</TitleModal>
                  </HeadModal>
                  {modalEditIsOpen && (
                     <BodyModal>
                        <form
                           onSubmit={e => {
                              updatePodcast(e, toEditPodcast);
                           }}
                        >
                           <Input type="hidden" value={toEditPodcast.id} name="id" />
                           <Input
                              type="text"
                              placeholder="Youtube Id"
                              name="youtube_id"
                              defaultValue={toEditPodcast.youtube_id}
                           />
                           <ContentButtons>
                              <Button color="crimson" onClick={closeModalEdit} as="div">
                                 CERRAR
                              </Button>
                              <Button text={Colors.secondary}>GUARDAR</Button>
                           </ContentButtons>
                        </form>
                     </BodyModal>
                  )}
               </GlobalModal>
            );
         }}
      </PodcastsContext.Consumer>
   );
};
