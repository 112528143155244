import React, {Component} from "react";
import Container from "../../components/Container";
import EventComediansProvider, {EventComediansContext} from "./EventComediansProvider";
import {useParams} from "react-router-dom";
import TableEventComedians from "./TableEventComedians";
import AddEventComedianModal from "./AddEventComedianModal";

const Comedians = (props) => {
    const { id_event } = useParams(); // Obtener id_event de los parámetros de la URL

    return (
        <Container {...props}>
            <EventComediansProvider id_event={id_event} {...props}>
                <EventComediansContext.Consumer>
                {({
                    eventComediansData,
                    openModalAdd,
                }) => (
                    <>
                        <TableEventComedians
                            eventComediansData={eventComediansData}
                            openModalAdd={openModalAdd}
                        />
                        <AddEventComedianModal />
                    </>
                )}
                </EventComediansContext.Consumer>
            </EventComediansProvider>
        </Container>
    );
};

export default Comedians;
