import React, {Component} from "react";
import {getToken, LoginService, userDataService, getCitiesService, getCountriesService} from "../helpers/Services";

export const GlobalContext = React.createContext();

export default class GlobalProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpen: false,
         modalIsOpen: false,
         toggleMenu: this.toggleMenu,
         toggleModal: this.toggleModal,
         // auth
         isLogin: false,
         logIn: this.logIn,
         logOut: this.logOut,
         isLoading: false,
         getNameCity: this.getNameCity,
         getNameCountry: this.getNameCountry,
      };
   }
   UNSAFE_componentWillMount() {
      this.getAuthStatus();
   }
   openLoad = () => this.setState({isLoading: true});
   toggleMenu = val => {
      if (this.state.modalIsOpen) return false;
      this.setState({
         isOpen: val,
      });
   };
   toggleModal = val => {
      this.setState({
         modalIsOpen: val,
      });
   };
   // auth actions
   getAuthStatus = () => {
      getToken().then(token => {
         const isLogin = token ? true : false;
         if (isLogin) {
            this.setState(
               {
                  token,
               },
               () => {
                  this.initApp();
               },
            );
         }
      });
   };
   initApp = () => {
      this.setState({
         isLoading: true,
      });
      this.getUserData();
   };
   logIn = e => {
      this.openLoad();
      e.preventDefault();
      const {user, password} = e.target;
      const toSend = {email: user.value, password: password.value};
      LoginService(toSend)
         .then(resp => resp.json())
         .then(resp => {
            const {data, status} = resp;
            sessionStorage.setItem("token", data.token);
            if (status === "success") {
               this.setState(
                  {
                     token: data.token,
                  },
                  () => this.initApp(),
               );
            } else {
               this.setState({
                  isLoading: false,
                  loginError: "Correo o contraseña invalidos",
               });
            }
         })
         .catch(() => {
            this.setState({
               isLoading: false,
               loginError: "Error de conexión",
            });
         });
   };
   getNameCity = id => {
      const {citiesData} = this.state;
      if (!citiesData) return "error";
      if (!id) return "El usuario no ingreso una ciudad";
      const city = citiesData.find(cit => Number(cit.id) === Number(id));
      return city ? city.name : id;
   };
   getNameCountry = id => {
      const {countriesData} = this.state;
      if (!countriesData) return "error";
      if (!id) return "El usuario no ingreso una ciudad";
      const country = countriesData.find(coun => Number(coun.id) === Number(id));
      return country.name;
   };
   getUserData = () => {
      getToken().then(token => {
         const data = {token: token};
         userDataService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  if (data.role_id === 1) {
                     this.logOut();
                     alert("No tienes permisos suficientes para continuar");
                  } else {
                     this.setState({
                        isLogin: true,
                        userData: {...data},
                        // userData: {...data, role_id: 2},
                     });
                  }
               }
            })
            .then(() => {
               this.getCountries();
            })
            .then(() => {
               this.getCities();
            })
            .catch(() => {
               this.logOut();
            });
      });
   };
   logOut = () => {
      this.setState({
         isLogin: false,
         isLoading: false,
         token: null,
      });
      sessionStorage.removeItem("token");
   };
   getCities = () => {
      getToken().then(token => {
         const data = {token};
         getCitiesService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  this.setState({
                     isLogin: true,
                     isLoading: false,
                     citiesData: data.filter(item => item.status > 0),
                  });
               }
            })
            .catch(err => console.log("Error de conexión"));
      });
   };
   getCountries = () => {
      getToken().then(token => {
         const data = {token};
         getCountriesService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  this.setState({
                     countriesData: [...data],
                  });
               }
            })
            .catch(err => console.log(err));
      });
   };
   render() {
      return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>;
   }
}
