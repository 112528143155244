const userPermission = [
   {
      permission: [2],
      path: "/home_page",
   },
   {
      permission: [2],
      path: "/comedians",
   },   
   {
      permission: [2],
      path: "/event_comedians/:id_event",
   },   
   {
      permission: [2],
      path: "/podcasts",
   },      
   {
      permission: [2],
      path: "/",
   },
   {
      permission: [2],
      path: "/ciudades",
   },
   {
      permission: [2],
      path: "/intereses",
   },
   {
      permission: [2],
      path: "/usuarios",
   },
   {
      permission: [2, 3],
      path: "/eventos",
   },
   {
      permission: [2],
      path: "/eventos/editar/:id",
   },
   {
      permission: [2],
      path: "/eventos/tickets/:id",
   },
   {
      permission: [2],
      path: "/notificaciones",
   },
   {
      permission: [2],
      path: "/membresias",
   },
   {
      permission: [2],
      path: "/membresias/list",
   },
   {
      permission: [2],
      path: "/membresias/codes",
   },
   {
      permission: [2],
      path: "/membresias/codes/:id_ticket",
   },
   {
      permission: [2],
      path: "/recuperar",
   },
   {
      permission: [2],
      path: "/recover-password",
   },
   // actualización
   {
      permission: [2, 3],
      path: "/checadores",
   },
   {
      permission: [2, 3],
      path: "/checadores/scan/:id",
   },
   {
      permission: [2],
      path: "/live_events",
   },
   {
      permission: [2],
      path: "/live_events/add",
   },
   {
      permission: [2],
      path: "/live_events/edit/:live_event_id",
   },
   {
      permission: [2],
      path: "/live_events/info/:live_event_id",
   },
   {
      permission: [2],
      path: "/live_events/create_ticket/:live_event_id",
   },
   {
      permission: [2],
      path: "/live_events/ticket_global/create",
   },
   {
      permission: [2],
      path: "/live_events/ticket_global",
   },
   {
      permission: [2],
      path: "/live_events/attendance/:live_event_id",
   },
];
export default userPermission;
