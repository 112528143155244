import React, {Component} from "react";
import Container from "../../components/Container";
import ComediansProvider, {ComediansContext} from "./ComediansProvider";
import TableComedians from "./TableComedians";
import AddComedianModal from "./AddComedianModal";
import EditComedianModal from "./EditComedianModal";

export default class Comedians extends Component {
    constructor(props) {
       super(props);
       this.state = {
          modalIsOpen: false,
       };
    }
    render() {
       return (
          <Container {...this.props}>
             <ComediansProvider {...this.props}>
                <ComediansContext.Consumer>
                {
                    ({
                        comediansData,
                        openModalAdd,
                        openModalEdit,
                        closeModalEdit,
                    }) => (
                        <>
                            <TableComedians
                            comediansData={comediansData}
                            openModalAdd={openModalAdd}
                            openModalEdit={openModalEdit}
                            closeModalEdit={closeModalEdit}
                            />
                            <AddComedianModal />
                            <EditComedianModal />
                        </>
                    )
                }
                </ComediansContext.Consumer>
             </ComediansProvider>
          </Container>
       );
    }
 }
 