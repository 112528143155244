import React, {useState, useEffect } from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import Text from "../../components/Text";
import {EventComediansContext} from "./EventComediansProvider";
import {getComediansService} from "../../helpers/Services";


export default AddComedian => {
   const [data, setData] = useState([]);

   useEffect(() => {

      getComediansService(data)
      .then(resp => resp.json())
      .then(resp => {
         const {data, status} = resp;
         if (status === "success") {
            setData(data);
         }
      })
      .catch(err => console.log(err));
   
   }, []); 

   return (
      <EventComediansContext.Consumer> 
         {({modalAddIsOpen, closeModalAdd, addEventComedian, errorsForm}) => (
            <GlobalModal modalIsOpen={modalAddIsOpen} closeModal={closeModalAdd} widthCard="35%">
               <HeadModal closeModal={closeModalAdd}>
                  <TitleModal>Agregar Comediante al evento</TitleModal>
               </HeadModal>
               {modalAddIsOpen && (
                  <BodyModal>
                     <form
                        onSubmit={e => {
                           addEventComedian(e);
                        }}
                     >
                        <Select name="id_comedian">
                           {
                              data.map((comedian) => (
                                 <option key={comedian.id} value={comedian.id}>{comedian.name}</option>
                              ))
                           }
                        </Select>
                        {errorsForm && <Text color="crimson">{errorsForm}</Text>}
                        <ContentButtons>
                           <Button color="crimson" onClick={closeModalAdd} as="div">
                              CERRAR
                           </Button>
                           <Button text={Colors.secondary}>GUARDAR</Button>
                        </ContentButtons>
                     </form>
                  </BodyModal>
               )}
            </GlobalModal>
         )}
      </EventComediansContext.Consumer>
   );
};
