import React from "react";
import GlobalModal from "../../components/GlobalModal";
import HeadModal from "../../components/modal/HeadModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import {Select, Input} from "../../components/FormsConfig";
import Text from "../../components/Text";
import Colors from "../../constants/Colors";
import Button from "../../components/Button";

export default function AddNotificationsModal({
   modalIsOpen, 
   closeModal, 
   action, 
   citiesCountry,
   activeTemes,
   changeCountry,
   changeCity,
   eventsCity,
   handleInterest,
   errorsForm
   }) {
   return (
      <GlobalModal modalIsOpen={modalIsOpen} closeModal={closeModal} widthCard="35%">
         <HeadModal closeModal={closeModal}>
            <TitleModal>Nueva ntotificación</TitleModal>
         </HeadModal>

         <BodyModal>
            <form onSubmit={action}>
               <Input type="text" placeholder="Titulo" name="title" required />
               <Input type="text" placeholder="Descripción" as="textarea" height="140px" name="description" required />
               {errorsForm && <Text color="crimson">{errorsForm}</Text>}
               <label>
                  País (Opcional)
               </label>
               <Select name="country" defaultValue={""} onChange={changeCountry}>
                  <option value={""}>Ninguno</option>
                  <option value={1}>México</option>
                  <option value={2}>Colombia</option>
               </Select>               
               
               {citiesCountry && (
                  <div>
                     <label>
                     Ciudad (Opcional)
                     </label>
                  <Select name="city" defaultValue={""} onChange={changeCity}>
                     <option value={""}>Ninguno</option>
                     {  
                        citiesCountry.map(item => {
                           return (
                              <option value={item.id} key={item.id}>
                                 {item.name}
                              </option>
                           );
                        })
                     }
                  </Select>
                  </div>
               )}

               {eventsCity && (
               <div>
                  <label>
                     Evento (Opcional)
                  </label>               
                  <Select name="event" >
                     <option value={""}>Ninguno</option>
                     {  
                           eventsCity.map(item => {
                              return (
                                 <option value={item.id} key={item.id}>
                                    {item.title}
                                 </option>
                              );
                           })
                        }
                  </Select>  
               </div>             
               )}
               
               <div>
                  <h5>Temas de interés</h5>
                  {  
                     activeTemes.map(item => {
                        return (
                           <div key={item.id}>
                           <input
                           key={item.id}
                           type="checkbox"
                           id={"interest" + item.id}
                           name={"interest" + item.id}
                           value={item.id}
                           checked={handleInterest()}
                           onChange={handleInterest()}
                           />
                           <label>{item.name}</label>
                        </div>                        
                        );
                     })
                  }                 
               </div>   

               <ContentButtons>
                  <Button color="crimson" onClick={closeModal} as="div">
                     CERRAR
                  </Button>
                  <Button text={Colors.secondary}>ENVIAR</Button>
               </ContentButtons>
            </form>
         </BodyModal>
      </GlobalModal>
   );
}

AddNotificationsModal.defaultProps = {
   data: {},
   modalIsOpen: false,
};
