import React, { Component } from "react";
import Container from "../../components/Container";
import AppLoader from "../../components/loader/AppLoader";
import { FormEdit, RowDiv, ColDiv, Label, Input } from "./styled";
import { getHomePage, getToken, postHomePage } from "../../helpers/Services";

export default class HomePage extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isLoading: true,
         token: "",
         homePage: {
            header_text: "",
            header_banner: "",
            header_link: "",
            left_text: "",
            left_banner: "",
            left_link: "",
            right_text: "",
            right_banner: "",
            right_link: "",
            bottom_text: "",
            bottom_banner: "",
            bottom_link: "",
         },
         files: {} // Estado para almacenar los archivos seleccionados
      };
   }

   componentDidMount() {
      this.setState({ isLoading: false });
      this.getHomePageService();
      this.getUserToken();
   }

   getHomePageService = () => {
      getHomePage()
         .then(res => res.json())
         .then(resp => {
            const { data, status } = resp;
            if (status === "success") {
               const homePage = data;
               this.setState({ homePage });
            }
         });
   };

   getUserToken = () => {
      getToken().then(token => {
         console.log("user token", token);
         this.setState({ token: token });
      });
   }

   handleChange = (e) => {
      const { name, value } = e.target;
      this.setState(prevState => ({
         homePage: {
            ...prevState.homePage,
            [name]: value
         }
      }));
   };

   handleFileChange = (e, bannerKey) => {
      const file = e.target.files[0];
      if (file) {
         this.setState(prevState => ({
            files: {
               ...prevState.files,
               [bannerKey]: file // Guardar el archivo en el estado para usarlo en FormData
            }
         }));
      }
   };

   handleSubmit = (e) => {
      e.preventDefault();
      const { token, homePage, files } = this.state;
      const formData = new FormData();

      // Añadir todos los campos de texto al FormData
      Object.keys(homePage).forEach(key => {
         formData.append(key, homePage[key]);
      });

      // Añadir todos los archivos al FormData
      Object.keys(files).forEach(key => {
         formData.append(key, files[key]);
      });

      // Enviar los datos mediante la función de servicio postHomePage
      postHomePage({ formData, token })
         .then(resp => resp.json())
         .then(resp => {
            const { status, data } = resp;
            if (status === "success") {
               alert("Datos guardados correctamente");
               window.location.reload();
            } else {
               console.log(resp);
               alert("Error al guardar");
            }
         })
         .catch(err => {
            console.log(err);
            alert("Error al guardar");
         });
   };

   render() {
      const { isLoading, homePage } = this.state;
      return (
         <Container {...this.props}>
            <FormEdit>
               <form onSubmit={this.handleSubmit}>
                  <RowDiv>
                     <h1>Home Page</h1>
                  </RowDiv>
                  <RowDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Top Texto</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.header_text} 
                           name="header_text" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Top Link</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.header_link} 
                           name="header_link" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Top Imagen</Label>
                        <img 
                           src={"https://freeticketapi.trebolbit.com/" + homePage.header_banner} 
                           style={{ width: "100%" }} 
                           alt="Top Imagen" 
                        />
                        <Input 
                           type="file" 
                           onChange={(e) => this.handleFileChange(e, 'header_banner')} 
                        />
                     </ColDiv>
                  </RowDiv>

                  {/* Sección de panel izquierdo */}
                  <RowDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Panel Izquierdo Texto</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.left_text} 
                           name="left_text" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Panel Izquierdo Link</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.left_link} 
                           name="left_link" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Panel Izquierdo Imagen</Label>
                        <img 
                           src={"https://freeticketapi.trebolbit.com/" + homePage.left_banner} 
                           style={{ width: "100%" }} 
                           alt="Left Imagen" 
                        />
                        <Input 
                           type="file" 
                           onChange={(e) => this.handleFileChange(e, 'left_banner')} 
                        />
                     </ColDiv>
                  </RowDiv>

                  {/* Sección de panel derecho */}
                  <RowDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Panel Derecho Texto</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.right_text} 
                           name="right_text" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Panel Derecho Link</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.right_link} 
                           name="right_link" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Panel Derecho Imagen</Label>
                        <img 
                           src={"https://freeticketapi.trebolbit.com/" + homePage.right_banner} 
                           style={{ width: "100%" }} 
                           alt="Right Imagen" 
                        />
                        <Input 
                           type="file" 
                           onChange={(e) => this.handleFileChange(e, 'right_banner')} 
                        />
                     </ColDiv>
                  </RowDiv>

                  {/* Sección de footer */}
                  <RowDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Footer Texto</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.bottom_text} 
                           name="bottom_text" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Footer Link</Label>
                        <Input 
                           placeholder="Texto de inicio" 
                           value={homePage.bottom_link} 
                           name="bottom_link" 
                           onChange={this.handleChange} 
                        />
                     </ColDiv>
                     <ColDiv colsm="12" colmd="4">
                        <Label>Footer Imagen</Label>
                        <img 
                           src={"https://freeticketapi.trebolbit.com/" + homePage.bottom_banner} 
                           style={{ width: "100%" }} 
                           alt="Bottom Imagen" 
                        />
                        <Input 
                           type="file" 
                           onChange={(e) => this.handleFileChange(e, 'bottom_banner')} 
                        />
                     </ColDiv>
                  </RowDiv>

                  <button type="submit">Guardar</button>
               </form>
            </FormEdit>
            <AppLoader isVisible={isLoading} />
         </Container>
      );
   }
}
