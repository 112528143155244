import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Select, Input, Label} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import {FieldFile} from "../../components/Fields";
import {ComediansContext} from "./ComediansProvider";

export default EditComedian => {
   return (
      <ComediansContext.Consumer>
         {({toEditComedian, modalEditIsOpen, closeModalEdit, updateComedian}) => {
            return (
               <GlobalModal modalIsOpen={modalEditIsOpen} closeModal={closeModalEdit} widthCard="40%">
                  <HeadModal closeModal={closeModalEdit}>
                     <TitleModal>Editar Comediante</TitleModal>
                  </HeadModal>
                  {modalEditIsOpen && (
                     <BodyModal>
                        <form
                           onSubmit={e => {
                              updateComedian(e, toEditComedian);
                           }}
                        >
                           <Input type="hidden" value={toEditComedian.id} name="id" />
                           <Input
                              type="text"
                              placeholder="Nombre del comediante"
                              name="name"
                              defaultValue={toEditComedian.name}
                           />
                           <Input
                              type="text"
                              placeholder="Descripción del comediante"
                              name="description"
                              defaultValue={toEditComedian.description}
                           />
                           <FieldFile fileType=".jpg, .jpeg" name="photo_file">
                              <Label marginBottom="0" as="span">
                                 Agregar imagen de comediante
                              </Label>
                           </FieldFile>                           
                           <ContentButtons>
                              <Button color="crimson" onClick={closeModalEdit} as="div">
                                 CERRAR
                              </Button>
                              <Button text={Colors.secondary}>GUARDAR</Button>
                           </ContentButtons>
                        </form>
                     </BodyModal>
                  )}
               </GlobalModal>
            );
         }}
      </ComediansContext.Consumer>
   );
};
