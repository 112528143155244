import React, {Component} from "react";

import {
   getToken, 
   getEventComediansService, 
   postEventComediansService, 
} from "../../helpers/Services";

export const EventComediansContext = React.createContext();
export default class EventComediansProvider extends Component {
   constructor(props) {
      super(props);
      console.log(props);
      this.state = {
         eventComediansData: [],
         // add
         addEventComedian: this.addEventComedian,
         closeModalAdd: this.closeModalAdd,
         modalAddIsOpen: false,
         openModalAdd: this.openModalAdd,
         // gral
         errorsForm: "",
      };
   }

   UNSAFE_componentWillMount() {
      const { id_event } = this.props;
      this.getComedians(id_event);
   }
   
   getComedians = (id_event) => {
      getToken().then(token => {
         const data = {token, id_event};
         getEventComediansService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  const comediansData = data.map(item => item.comedian);
                  this.setState({ eventComediansData: [...comediansData] });
                  //this.setState({eventComediansData: [...data],});
               }
            })
            .catch(err => console.log(err));
      });
   };
   
   // add
   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
      
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };
   
   addEventComedian = e => {
      e.preventDefault();
      const {eventComediansData} = this.state;
      const myForm = e.target;
      const { id_event } = this.props;
      const id_comedian = myForm.id_comedian.value;
      const data = {
         id_event,
         id_comedian: id_comedian
      };

      getToken(data).then(token => {
         const toSend = {data, token};
         postEventComediansService(toSend)
            .then(resp => resp.json())
            .then(resp => {
               const {status, data} = resp;
               alert("paso6")
               if (status === "success") {
                  this.closeModalAdd();
                  
                  const newEventComedians = [...eventComediansData, {...data}];
                  this.setState(
                     {
                        comediansData: newEventComedians,
                     },
                     () => {
                        this.closeModalAdd();
                        window.location.reload();
                     },
                  );
                  
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });
      

   };

   render() {
      return <EventComediansContext.Provider value={this.state}>{this.props.children}</EventComediansContext.Provider>;
   }
}
