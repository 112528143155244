import React from "react";
import GlobalModal from "../../components/GlobalModal";
import {TitleModal, BodyModal, ContentButtons} from "../../components/ModalConfig";
import HeadModal from "../../components/modal/HeadModal";
import {Input} from "../../components/FormsConfig";
import Button from "../../components/Button";
import Colors from "../../constants/Colors";
import Text from "../../components/Text";
import {ComediansContext} from "./ComediansProvider";
import {FieldFile} from "../../components/Fields";
import {Label, Select} from "../../components/FormsConfig";

export default AddComedian => {
   return (
      <ComediansContext.Consumer> 
         {({modalAddIsOpen, closeModalAdd, addComedian, errorsForm}) => (
            <GlobalModal modalIsOpen={modalAddIsOpen} closeModal={closeModalAdd} widthCard="35%">
               <HeadModal closeModal={closeModalAdd}>
                  <TitleModal>Agregar Comediante</TitleModal>
               </HeadModal>
               {modalAddIsOpen && (
                  <BodyModal>
                     <form
                        onSubmit={e => {
                           addComedian(e);
                        }}
                     >
                        <Input type="text" placeholder="Nombre del comediante" name="name" />
                        <Input type="text" placeholder="Descripción del comediante" name="description" />
                           <FieldFile fileType=".jpg, .jpeg" name="photo_file">
                              <Label marginBottom="0" as="span">
                                 Agregar imagen de comediante
                              </Label>
                           </FieldFile>

                        {errorsForm && <Text color="crimson">{errorsForm}</Text>}
                        <ContentButtons>
                           <Button color="crimson" onClick={closeModalAdd} as="div">
                              CERRAR
                           </Button>
                           <Button text={Colors.secondary}>GUARDAR</Button>
                        </ContentButtons>
                     </form>
                  </BodyModal>
               )}
            </GlobalModal>
         )}
      </ComediansContext.Consumer>
   );
};
