import React from "react";
import styled from "styled-components";
import {Router, Route, Switch} from "react-router-dom";
import {createBrowserHistory} from "history";
import {GlobalContext} from "./providers/GlobalProvider";
import LiveEventsProvider from "./providers/LiveEventsProvider";
import LiveTicketsProvider from "./providers/LiveTicketsProvider";
import MembershipProvider from "./providers/MembershipProvider";
import Cities from "./views/Cities";
import Events from "./views/Events";
import Menu from "./components/sidemenu/Menu";
import Favorites from "./views/Favorites";
import Users from "./views/users/index";
import Memberships from "./views/membership";
import MembershipsCodes from "./views/MembershipCodes";
import Notifications from "./views/Notifications";
import EditEvent from "./views/events/views/EditEvent";
import CreateTickets from "./views/events/views/CreateTikets";
import ErrorView from "./views/ErrorView";
import AppLoader from "./components/loader/AppLoader";
import RecoverPassword from "./auth/RecoverPassword";
import NewPassword from "./publicViews/newPassword";
import CheckerView from "./views/checker/CheckerView";
import ScanView from "./views/scanQr/ScanView";
import LiveView from "./views/live/LiveView";
import CreateShowView from "./views/live/create/CreateShowView";
import EditShowView from "./views/live/edit/EditShowView";
import CreateTicket from "./views/live/tickets_create/CreateTicket";
import LiveAttendanceView from "./views/live/assistence/LiveAttendanceView";
import InfoShowView from "./views/live/info/InfoView";
import UniveralTicket from "./views/live/tickets_create/UniversalTicket";
import UniversalTicketsView from "./views/live/universal_tickets/UniversalTicketsView";
import MembershipCodeDetail from "./views/MembershipCodeDetails";
import HomePage from "./views/homePage/HomePage";
import Comedians from "./views/comedians/Comedians";
import Podcasts from "./views/podcasts/Podcasts";
import EventComedians from "./views/eventComedians/EventComedians";


const MainContent = styled.section`
   width: 100%;
   height: 100%;
`;

const history = createBrowserHistory();

function AppRoutes(props) {
   return (
      <Router history={history}>
         <>
            {props.isLogin && <Menu />}
            <MainContent>
               <Switch>
                  {Object.keys(routes).map((x) => {
                     const item = routes[x];
                     return <Route path={item.path} component={item.component} {...props} key={x} exact={item.exact} />;
                  })}
                  <Route component={ErrorView} />
               </Switch>
            </MainContent>
         </>
      </Router>
   );
}

const AppRouter = (props) => (
   <GlobalContext.Consumer>
      {({isLogin, logIn, logOut, isLoading}) => (
         <MembershipProvider>
            <LiveEventsProvider>
               <LiveTicketsProvider>
                  <AppRoutes {...props} isLogin={isLogin} logIn={logIn} logOut={logOut} />
                  <AppLoader isVisible={isLoading} />
               </LiveTicketsProvider>
            </LiveEventsProvider>
         </MembershipProvider>
      )}
   </GlobalContext.Consumer>
);

export default AppRouter;

const routes = [
   {
      path: "/",
      exact: true,
      component: Cities,
   },
   {
      path: "/ciudades",
      exact: true,
      component: Cities,
   },
   {
      path: "/intereses",
      exact: true,
      component: Favorites,
   },
   {
      path: "/usuarios",
      exact: true,
      component: Users,
   },
   {
      path: "/eventos",
      exact: true,
      component: Events,
   },
   {
      path: "/eventos/editar/:id",
      exact: true,
      component: EditEvent,
   },
   {
      path: "/eventos/tickets/:id",
      exact: true,
      component: CreateTickets,
   },
   {
      path: "/notificaciones",
      exact: false,
      component: Notifications,
   },
   {
      path: "/membresias/list",
      exact: true,
      component: Memberships,
   },
   {
      path: "/membresias/codes",
      exact: true,
      component: MembershipsCodes,
   },
   {
      path: "/membresias/codes/:id_ticket",
      exact: true,
      component: MembershipCodeDetail,
   },
   {
      path: "/recuperar",
      exact: true,
      component: RecoverPassword,
   },
   {
      path: "/recover-password",
      exact: true,
      component: NewPassword,
   },
   // actualización
   {
      path: "/checadores",
      exact: true,
      component: CheckerView,
   },
   {
      path: "/checadores/scan/:id",
      exact: true,
      component: ScanView,
   },
   {
      path: "/live_events",
      exact: true,
      component: LiveView,
   },
   {
      path: "/live_events/edit/:live_event_id",
      exact: true,
      component: EditShowView,
   },
   {
      path: "/live_events/info/:live_event_id",
      exact: true,
      component: InfoShowView,
   },
   {
      path: "/live_events/add",
      exact: true,
      component: CreateShowView,
   },
   {
      path: "/live_events/create_ticket/:live_event_id",
      exact: true,
      component: CreateTicket,
   },
   {
      path: "/live_events/ticket_global",
      exact: true,
      component: UniversalTicketsView,
   },
   {
      path: "/live_events/ticket_global/create",
      exact: true,
      component: UniveralTicket,
   },
   {
      path: "/live_events/attendance/:live_event_id",
      exact: true,
      component: LiveAttendanceView,
   },
   {
      path: "/home_page",
      exact: true,
      component: HomePage,
   },
   {
      path: "/comedians",
      exact: true,
      component: Comedians,
   },
   {
      path: "/event_comedians/:id_event",
      exact: true,
      component: EventComedians,
   },   
   {
      path: "/podcasts",
      exact: true,
      component: Podcasts,
   },   
];
