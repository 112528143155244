import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Seeker, ItemHeader, ActionButton} from "../../components/TableComponents";
import {ReactComponent as EditIcon} from "../../icons/edit.svg";
import {ReactComponent as TicketIcon} from "../../icons/tickets.svg";
import {ReactComponent as SendIcon} from "../../icons/send.svg";
import {ReactComponent as ScanIcon} from "../../icons/scan.svg";
import {ReactComponent as WatchIcon} from "../../icons/eye.svg";

import HeaderView from "../../components/toViews/HeaderView";
// import styled from "styled-components";
import {AddButton} from "../../components/Button";
import Text from "../../components/Text";
import {filterByText, filterByKey} from "../../constants/Filters";
import {Select} from "../../components/FormsConfig";
import {GlobalContext} from "../../providers/GlobalProvider";

const EditorButton = ({Icon, refTo}) => (
   <ActionButton iconColor="#398bf7" as="a" href={refTo ? refTo : ""} margin="1em 0">
      <Icon width="20px" height="20px" fill="#398bf7" className="icon" />
   </ActionButton>
);

const HeaderActions = ({onFilter, title, goToAdd}) => (
   <HeaderView title={title}>
      <ItemHeader>
         <AddButton onClick={goToAdd} />
      </ItemHeader>
      <ItemHeader>
         <Select name="country_id" defaultValue={0} onChange={e => onFilter("country_id", e.target.value)} marginB="0">
            <option value={0}>Todos los países</option>
            <option value={1}>México</option>
            <option value={2}>Colombia</option>
         </Select>
      </ItemHeader>
      <ItemHeader>
         <Seeker onFilter={val => onFilter("filterText", val)} place="Buscar por evento" />
      </ItemHeader>
   </HeaderView>
);

/* const StatusButton = styled.button`
   width: 100%;
   color: ${props => props.color || "grey"};
   font-size: 0.9em;
   background: rgba(100, 100, 100, 0.3);
   border: none;
   outline: none;
   padding: 0.5em;
   border-radius: 4px;
   cursor: pointer;
   &:active {
      transform: scale(0.8);
   }
`; */

class TableEventsView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         DataEvent: this.props.eventsData,
         filterText: "",
         country_id: 0,
      };
   }
   UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.eventsData === this.state.DataEvent) return false;
      this.setState(
         {
            DataEvent: this.props.eventsData,
         },
         () => {
            this.fByCountry();
         },
      );
   }
   onFilter = (key, val) => {
      this.setState(
         {
            [key]: val,
         },
         () => {
            setTimeout(() => {
               this.fByCountry();
            }, 100);
         },
      );
   };
   filterItems = dataEvent => {
      const filterText = this.state.filterText;
      if (filterText.length > 2) {
         let filteredItems = dataEvent.filter(event => filterByText(event.title, filterText));
         this.setState({
            filteredItems: filteredItems,
         });
      } else {
         this.setState({
            filteredItems: dataEvent,
         });
      }
   };
   fByCountry = () => {
      // this.filterBy("country_id", this.state.country_id);
      const val = this.state.country_id;
      const {DataEvent} = this.state;
      let filtered = DataEvent.filter(event => filterByKey(event.country_id, Number(val), 0));
      this.filterItems(filtered);
   };
   fbCity = dataEvent => {
      const val = Number(this.state.city_id);
      let filtered = dataEvent.filter(event => (val > 0 ? Number(event.city_id) === val : true));
      this.filterText(filtered);
   };
   Columns = [
      {
         name: "id",
         selector: "id",
         sortable: true,
         maxWidth: "50px",
      },
      {
         name: "Visibilidad",
         selector: "visibility",
         maxWidth: "150px",
         cell: row => (
            <>
               <Text  color={Number(row.visibility) === 0 ? "red" : "green"}>{Number(row.visibility) === 0 ? "No Publicado" : "Publicado"}</Text>
            </>
         ),
         sortable: true,
      },
      {
         name: "Evento",
         selector: "title",
         sortable: true,
         maxWidth: "380px",
      },
      {
         name: "País",
         button: false,
         selector: "country_id",
         compact: true,
         maxWidth: "100px",
         cell: row => (
            <>
               <Text align="center">{getNameCountry(row.country_id)}</Text>
            </>
         ),
      },
      {
         name: "Ciudad",
         selector: "city_id",
         sortable: true,
         maxWidth: "200px",
         cell: row => (
            <>
               <Text>{this.props.getNameCity(row.city_id)}</Text>
            </>
         ),
      },
      {
         name: "Estado",
         selector: "status",
         sortable: true,
         maxWidth: "50px",
         cell: row => <WatchIcon width="20px" height="20px" fill={Number(row.status) === 0 ? "red" : "green"} title={Number(row.status) === 0 ? "Expectativa" : "Activo"}/>,
      },
      /* {
         name: "Tickets entregados",
         button: false,
         selector: "deliver",
         maxWidth: "150px",
         compact: true,
         cell: row => (
            <>
               <Text align="center">{`${row.deliver || "0"}/${row.delivered || "0"}`}</Text>
            </>
         ), 
      }, */
      {
         name: "",
         button: false,
         cell: row => (
            <>
               {this.props.isAdmin && <EditorButton val={row.id} refTo={`eventos/editar/${row.id}`} Icon={EditIcon} />}
               <EditorButton val={row.id} refTo={`eventos/tickets/${row.id}`} Icon={TicketIcon} />
               {this.props.isAdmin  && <EditorButton val={row.id} action={this.props.openModalEdit} Icon={SendIcon} />}
               <EditorButton val={row.id} refTo={`checadores/scan/${row.id}`} Icon={ScanIcon} />
               <EditorButton val={row.id} refTo={`event_comedians/${row.id}`} Icon={ScanIcon} />
            </>
         ),
         maxWidth: "50px",
      },
   ];

   render() {
      const {filteredItems} = this.state;
      const paginationOptions = {rowsPerPageText: "Filas por página", rangeSeparatorText: "de"};
      return (
         <DataTable
            title="Eventos"
            header={null}
            keyField="id"
            columns={this.Columns}
            data={filteredItems}
            subHeader
            subHeaderComponent={
               <HeaderActions
                  onFilter={this.onFilter}
                  title="Eventos"
                  goToAdd={() => this.props.history.push("/eventos/editar/add")}
                  isAdmin={this.props.isAdmin}
               />
            }
            pagination
            paginationComponentOptions={paginationOptions}
            fixedHeader
            noDataComponent="No hay eventos registrados"
            style={{display: "block", height: "80%", overflowX: "visible", width: "100%"}}
         />
      );
   }
}

const TableEvents = props => (
   <GlobalContext.Consumer>
      {({citiesData, userData, getNameCity}) => {
         // if (!citiesData) return <AppLoader isVisible={true} />;
         return <TableEventsView citiesData={citiesData} isAdmin={userData.role_id === 2} getNameCity={getNameCity} {...props} />;
      }}
   </GlobalContext.Consumer>
);
export default TableEvents;

const getNameCountry = id => {
   switch (id) {
      case 1:
         return "México";
      case 2:
         return "Colombia";
      default:
         return " ";
   }
};
