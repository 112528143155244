import React, {Component} from "react";

import {
   getToken, 
   getComediansService, 
   postComedianService, 
   updateComedianService
} from "../../helpers/Services";

export const ComediansContext = React.createContext();
export default class ComediansProvider extends Component {
   constructor(props) {
      super(props);
      this.state = {
         comediansData: [],
         // add
         addComedian: this.addComedian,
         closeModalAdd: this.closeModalAdd,
         modalAddIsOpen: false,
         openModalAdd: this.openModalAdd,

         // edit
         modalEditIsOpen: false,
         closeModalEdit: this.closeModalEdit,
         openModalEdit: this.openModalEdit,
         toEditComedian: [],
         updateComedian: this.updateComedian,
         // gral
         errorsForm: "",
      };
   }

   UNSAFE_componentWillMount() {
      this.getComedians();
   }
   
   getComedians = () => {
      getToken().then(token => {
         const data = {token};
         getComediansService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {data, status} = resp;
               if (status === "success") {
                  this.setState({
                     comediansData: [...data],
                  });
               }
            })
            .catch(err => console.log(err));
      });
   };
   
   // add
   openModalAdd = () => {
      this.setState({modalAddIsOpen: true});
      
   };
   closeModalAdd = () => {
      this.setState({modalAddIsOpen: false, errorsForm: ""});
   };
   
   addComedian = e => {
      e.preventDefault();
      const myForm = e.target;
      const {comediansData} = this.state;
      const name = myForm.name.value;
      const description = myForm.description.value;
      const photo_file = myForm.photo_file;

      const data = {
         name,
         description,
         photo_file
      };

      const newForm = new FormData(e.target);

      getToken(data).then(token => {
         //const toSend = {...data, token};
         const toSend = {newForm, token};
         postComedianService(toSend)
            .then(resp => resp.json())
            .then(resp => {
               const {status, data} = resp;
               if (status === "success") {
                  const newComedians = [...comediansData, {...data}];
                  this.setState(
                     {
                        comediansData: newComedians,
                     },
                     () => {
                        this.closeModalAdd();
                     },
                  );
               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });

   };
   
   // edit
   updateComedian = e => {
      e.preventDefault();
      const myForm = e.target;
      const id = Number(e.target.id.value);
      const name = myForm.name.value;
      const description = myForm.description.value;
      const updatedComedian = {
         id,
         name,
         description,
      };
      const {comediansData} = this.state;
      const newComedians = comediansData.map(comedian => {
         if (comedian.id === id) {
            return {...updatedComedian};
         }
         return comedian;
      });

      const newForm = new FormData(e.target);
      getToken().then(token => {
         const data = {newForm, token};
         updateComedianService(data)
            .then(resp => resp.json())
            .then(resp => {
               const {status} = resp;
               if (status === "success") {
                  this.setState(
                     {
                        comediansData: newComedians
                     },
                     () => {
                        this.closeModalEdit();
                     },
                  );
                  
                 //Reload the page
                 window.location.reload();


               } else {
                  console.log(resp);
               }
            })
            .catch(err => console.log(err));
      });

      this.setState(
         {
            comediansData: newComedians,
         },
         () => {
            this.closeModallEdit();
         },
      );
   };
   closeModalEdit = () => {
      this.setState({modalEditIsOpen: false, toEditComedian: {}, errorsForm: ""});
   };
   openModalEdit = id => {
      const {comediansData} = this.state;
      let toEditComedian = comediansData.find(x => Number(x.id) === Number(id));
      return this.setState({modalEditIsOpen: true, toEditComedian: toEditComedian});
   };

   render() {
      return <ComediansContext.Provider value={this.state}>{this.props.children}</ComediansContext.Provider>;
   }
}
