import React, {Component} from "react";
import Container from "../components/Container";
import AppLoader from "../components/loader/AppLoader";
import TableNotifications from "./notifications/TableNotifications";
import AddNotificationsModal from "./notifications/AddNotificationModal";
import {getInterestService, getCitiesService, getEventsListService, sendNotification} from "../helpers/Services";


export default class Notifications extends Component {
   constructor(props) {
      super(props);
      this.state = {
         notificationsData: [],
         isLoading: true,
         dataEvent: [],
         AllCities: [],
         interest: [],
      };
   }

   componentDidMount() {
      this.getLastNotifications();
      this.getInterest();
   }
   
   getLastNotifications = () => {
      //sessionStorage.setItem('token', 'value');
      /*  const myToken = getToken.then(tok => {
         if (tok === "expired") {
            return updateToken.then(token => {
               this.getLastNotifications();
            });
         }
      }); */

      const notifications = [
         {
            id: "10",
            title: "Nuevos boletos",
            description: "Obten los nuevos boletos disponibles",
            date: "2019-06-12 20:30:00",
         },
         {
            id: "1",
            title: "Boletos para el pròximo estreno",
            description:
               "Consigue entradas para la premier de esta semana, Consigue entradas para la premier de esta semana, Consigue entradas para la premier de esta semana, Consigue entradas para la premier de esta semana",
            date: "2019-06-10 14:22:00",
         },
      ];
      this.setState({
         notifications: notifications,
         isLoading: false,
      });
   };

   getInterest = () => {
      const data = {token: sessionStorage.getItem("token")};
      getInterestService(data)
         .then(res => res.json())
         .then(resp => {
            const {data, status} = resp;
            if (status === "success") {
               const activeTemes = data.filter(item => Number(item.status) === 1);
               this.setState({
                  activeTemes                  
               });
            }
         });
   };

   changeCountry = e => {

      const country = e.target.value;
      const data = {token: sessionStorage.getItem("token")};
      getCitiesService(data)
         .then(res => res.json())
         .then(resp => {
            const {data, status} = resp;
            if (status === "success") {
               const cities = data.filter(item => Number(item.status) === 1);
               const citiesCountry = cities.filter(item => Number(item.country_id) === Number(country));
               this.setState({
                  citiesCountry                  
               });
            }
         }); 
   };

   changeCity = e => {
      const city = e.target.value;
      const data = {token: sessionStorage.getItem("token")};
      getEventsListService(data)
         .then(res => res.json())
         .then(resp => {
            const {data, status} = resp;
            if (status === "success") {
               const events = data.filter(item => Number(item.status) === 1);
               const eventsCity = events.filter(item => Number(item.city_id) === Number(city));
               console.log(eventsCity);
               this.setState({
                  eventsCity                  
               });
            }
         }); 
   }

   addNotification = e => {
      e.preventDefault();
      const {title, description, country, city, event} = e.target;
      const {notifications} = this.state;
      const newNoti = {
         token: sessionStorage.getItem("token"),
         id: "20",         
         title: title.value,
         description: description.value,
         country:"",
         city:"",
         event:"",
         date: new Date()
      };
      if(country){
         newNoti.country = country.value;
      }      
      if(city){
         newNoti.city = city.value;
      }
      if(event){
         newNoti.event = event.value;
      }

      sendNotification(newNoti)
         .then(res => res.json())
         .then(resp => {
            const {data, status} = resp;
            if (status === "success") {
               console.log(data);
               alert("Notificación enviada a " + data.length + " usuarios !");               
               window.location.reload();
            }else{
               alert("error sending notifications");
            }
         }); 

      //const newNotifications = [...notifications, {...newNoti}];
      //console.log(newNotifications);
      //this.setState({
      //   notifications: newNotifications,
      //   modalAdd: false,
      //});
   };

   handleInterest = e => {
      //console.log(e);
   }

   render() {
      const {notifications, isLoading, modalAdd, citiesCountry, activeTemes, eventsCity} = this.state;
      return (
         <Container {...this.props}>
            {notifications && <TableNotifications data={notifications} openAdd={() => this.setState({modalAdd: true})} />}

            <AddNotificationsModal
               modalIsOpen={modalAdd}
               closeModal={() => {
                  this.setState({modalAdd: false});
               }}
               action={this.addNotification}
               citiesCountry = {citiesCountry}
               eventsCity = {eventsCity}
               activeTemes = {activeTemes}
               changeCountry={this.changeCountry}
               changeCity = {this.changeCity}
               handleInterest = {this.handleInterest}
               
            />
            <AppLoader isVisible={isLoading} />
         </Container>
      );
   }
}
